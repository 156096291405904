import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    beforeEnter: () => {
      // window.location.href = 'https://afinandocerebro.com.br/';
    },
    component: () => import(/* webpackChunkName: "about" */ '../App.vue')
  },
  {
    path: '/game',
    name: 'Game',
    component: () => import(/* webpackChunkName: "about" */ '../components/Game.vue')
  },
  {
    path: '/treino',
    name: 'Treino',
    component: () => import(/* webpackChunkName: "about" */ '../components/Treino.vue')
  }
]

const router = new VueRouter({
  mode:'history',
 
  routes
})

export default router
